import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import './i18n';
import './index.scss';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import App from './App';
import ModalContextProvider from './context/ModalContextProvider';
import GeneralContextProvider from './context/GeneralContextProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ModalContextProvider>
            <GeneralContextProvider>
              <App />
            </GeneralContextProvider>
        </ModalContextProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </BrowserRouter>
  // </React.StrictMode>
);