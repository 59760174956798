import React from 'react'
import cn from 'classnames'
import './ModalWindow.scss'
import { useModal } from '../hooks/useModal';
import {Close} from "@mui/icons-material";

const SecondModalWindow = ({children}) => {
  const {isSecondOpen, closeSecond} = useModal()

  if(!isSecondOpen) return null

  const clickHandler = (e) => {
    if(e.target === e.currentTarget) closeSecond()
  }

  return (
    <div className={cn('modal-window')} onClick={clickHandler}>
      <Close onClick={() => closeSecond()} style={{position: "absolute", top: '2%', right: '2%', color: '#fff', cursor: 'pointer', zIndex: 2000}}/>
      <div className='modal-content'>
        {children}
      </div>
    </div>
  )
}

export default SecondModalWindow