import React, { useCallback, useState } from 'react'
import { createContext } from 'react'

const id = localStorage.getItem('maktun-subject-id') || ''

export const GeneralContext = createContext({
    subject_id: null,
    setSubjectId: (number) => {},
    cointypes: null,
    setCointypes: (array) => {},
    user: null,
    setUser: (userData) => {},
    flags: null,
    setFlags: (flagsData) => {},
})

const GeneralContextProvider = ({children}) => {
  const [subject_id, setSubjectId] = useState(id ? +id : 1)
  const [cointypes, setCointypes] = useState(null)
  const [user, setUser] = useState(null)
  const [flags, setFlags] = useState(null)

    return (
    <GeneralContext.Provider value={{subject_id, setSubjectId, cointypes, setCointypes, user, setUser, flags, setFlags}}>
        {children}
    </GeneralContext.Provider>
  )
}

export default GeneralContextProvider