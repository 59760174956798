import React from 'react'
import './MobileModal.scss'
import { Copyright, Email, Telegram, WhatsappRounded, FacebookRounded } from '@mui/icons-material';
import {
  EMAIL_LINK,
  TELEGRAM_LINK,
  VIBER_LINK,
  WHATSAPP_LINK,
  FACEBOOK_LINK,
  VK_LINK,
  GOOGLE_PLAY_LINK,
  APP_STORE_LINK,
  HUAWEI_LINK
} from "../../utils/constants";
import { useTranslation } from 'react-i18next';

const MobileModal = () => {
    const {t} = useTranslation()

  return (
    <div className='mobile-modal'>
        <img src='/static_files/logo.png' alt="Logo" className='mobile-modal__logo'/>
        <p className='mobile-modal__title'>{t('mobile.title')}</p>
        <p className='mobile-modal__text'>{t('mobile.body')}</p>

        <div className='app-markets'>
            <a className='app-markets__link' href={GOOGLE_PLAY_LINK} target={"_blank"} rel="noreferrer">
                <img src='/static_files/google.png' alt="Google play"/>
            </a>
            <a className='app-markets__link' href={APP_STORE_LINK} target={"_blank"} rel="noreferrer">
                <img src='/static_files/appstore.png' alt="App store"/>
            </a>
            <a className='app-markets__link' href={HUAWEI_LINK} target={"_blank"} rel="noreferrer">
                <img src='/static_files/huawei.png' alt="App gallery"/>
            </a>
        </div>

        <div className='mobile-modal__footer'>
            <p><Copyright style={{color: "#fff", fontSize: "16px", marginLeft: "5px"}}/>{t('footer.maktun')}</p>
            <div className='social-networks'>
                <a href={EMAIL_LINK}>
                    <Email style={{color: "#fff", width: "30px", height: "30px"}}/>
                </a>

                <a href={TELEGRAM_LINK} target={"_blank"} rel="noreferrer">
                    <Telegram style={{color: "#fff", width: "30px", height: "30px"}}/>
                </a>

                <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">
                    <WhatsappRounded style={{color: "#fff", width: "30px", height: "30px"}}/>
                </a> 

                <a href={VIBER_LINK} target="_blank" rel="noreferrer">
                    <img src='/static_files/sidebar/Viber.png' alt="Viber logo"/>
                </a>

                <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
                    <FacebookRounded style={{color: "#fff", width: "30px", height: "30px"}}/>
                </a>

                <a href={VK_LINK} target="_blank" rel="noreferrer">
                    <img src='/static_files/sidebar/VK.png' alt="VK logo"/>
                </a>
            </div>
        </div>
    </div>
  )
}

export default MobileModal