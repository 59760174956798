import React, { createContext, useCallback, useState } from 'react'
import ModalWindow from './ModalWindow'
import SecondModalWindow from './SecondModalWindow';
import TouchableModal from './TouchableModal';


export const ModalContext = createContext({
    isOpen: false,
    isSecondOpen: false,
    isTouchableOpen: false,
    open: () => {},
    openSecond: () => {},
    openTouchable: () => {},
    close: () => {},
    closeSecond: () => {},
    closeTouchable: () => {},
    setModalContent: (modalContent) => {},
    setTouchableModalContent: (modalContent) => {},
    setSecondModalContent: (modalContent) => {}
})

const ModalContextProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSecondOpen, setIsSecondOpen] = useState(false)
  const [isTouchableOpen, setIsTouchableOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [secondModalContent, setSecondModalContent] = useState(null)
  const [touchableModalContent, setTouchableModalContent] = useState(null)
  
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  const openSecond = useCallback(() => setIsSecondOpen(true), [])
  const closeSecond = useCallback(() => setIsSecondOpen(false), [])

  const openTouchable = useCallback(() => setIsTouchableOpen(true), [])
  const closeTouchable = useCallback(() => setIsTouchableOpen(false), [])

    return (
    <ModalContext.Provider value={{isOpen, open, close, setModalContent, 
    isSecondOpen, openSecond, closeSecond, setSecondModalContent, 
    isTouchableOpen, openTouchable, closeTouchable, setTouchableModalContent}}>
        {children}
        <TouchableModal>
          {touchableModalContent}
        </TouchableModal>
        <ModalWindow>
          {modalContent}
        </ModalWindow>
        <SecondModalWindow>
          {secondModalContent}
        </SecondModalWindow>

    </ModalContext.Provider>
  )
}

export default ModalContextProvider