import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation} from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import Loader from './components/Loader/Loader'

import MobileModal from './components/MobileModal/MobileModal';
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'))
const CatalogPage = lazy(() => import('./pages/CatalogPage/CatalogPage')) 
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const CurrenciesPage = lazy(() => import('./pages/CurrenciesPage/CurrenciesPage'))
const NotesPage = lazy(() => import('./pages/CurrenciesPage/NotesPage/NotesPage'))
const NotePage = lazy(() => import('./pages/CurrenciesPage/NotesPage/NotePage/NotePage'))
const CollectionPage = lazy(() => import('./pages/CollectionPage/CollectionPage'))
const FilteredNotesPage = lazy(() => import('./pages/CurrenciesPage/NotesPage/FilteredNotesPage'))
const NotesNotFoundPage = lazy(() => import('./pages/NotesNotFoundPage/NotesNotFoundPage'))
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'))
const ProgressBar = lazy(() => import('./pages/ProgressBar/ProgressBar'))
const UnrecognizedNotesPage = lazy(() => import('./pages/CurrenciesPage/NotesPage/UnrecognizedNotesPage'))
const ProgressBarRestored = lazy(() => import('./pages/ProgressBar/ProgressBarRestored'))
const UnrecognizedCatalogNotesPage = lazy(() => import('./pages/CurrenciesPage/NotesPage/UnrecognizedCatalogNotesPage'))
const AllNotesPage = lazy(() => import('./pages/CurrenciesPage/NotesPage/AllNotesPage'))
const RegistrationPage = lazy(() => import('./pages/RegistrationPage/RegistrationPage'))
const FAQPage = lazy(() => import('./pages/FaqPage/FaqPage'))
const StatisticPage = lazy(() => import('./pages/StatisticPage/StatisticPage'))



const App = () => {
  if (isMobile) return <MobileModal />

  return (
    <Suspense fallback={<Loader/>}>
        <Routes>
          <Route path="/" element={<LoginPage/>}/>
          <Route path="/registration" element={<RegistrationPage/>}/>

          <Route path="/catalog" element={<CatalogPage />}/>
          <Route path="/catalog/country/:country_id" element={<CurrenciesPage />}/>
          <Route path="/catalog/country/:country_id/currency/:id" element={<NotesPage />}/>
          <Route path="/catalog/currency/:id" element={<NotePage />}/>
          <Route path="/catalog/countries/currencies/notes/notfound/:type/:searchword" element={<NotesNotFoundPage />}/>
          <Route path="/catalog/filter/country/:country_id/currency/notes/:type/:searchword" element={<FilteredNotesPage />}/>

          <Route path="/catalog/unrecognized" element={<UnrecognizedCatalogNotesPage />}/>
          <Route path="/list/:list/unrecognized/:flag_id" element={<UnrecognizedNotesPage />}/>

          <Route path="/list/:list/:flag_id" element={<CollectionPage />}/>
          <Route path="/list/:list/:flag_id/country/:country_id" element={<CurrenciesPage />}/>
          <Route path="/list/:list/:flag_id/country/:country_id/currency/:id" element={<NotesPage />}/>
          <Route path="/list/:list/:flag_id/currency/note/:id" element={<NotePage />}/>
          <Route path="/list/:list/:flag_id/countries/currencies/notes/notfound/:type/:searchword" element={<NotesNotFoundPage />}/>
          <Route path="/list/:list/:flag_id/filter/country/:country_id/currency/notes/:type/:searchword" element={<FilteredNotesPage />}/>

          <Route path="/list/:list/:flag_id/country/all-notes/:country_id" element={<AllNotesPage />}/>
          <Route path="/list/:list/:flag_id/all-notes/:country_id" element={<AllNotesPage />}/>
          <Route path="/catalog/country/all-notes/:country_id" element={<AllNotesPage />}/>

          <Route path="/restore" element={<ProgressBar />}/>
          <Route path="/statistic" element={<StatisticPage />}/>
          <Route path="/profile" element={<ProfilePage />}/>
          <Route path="/faq" element={<FAQPage />}/>

          <Route path="*" element={<PageNotFound />}/>
        </Routes>
    </Suspense>
  )
}

export default App