import React from 'react'
import cn from 'classnames'
import './ModalWindow.scss'
import { useModal } from '../hooks/useModal';
import {Close} from "@mui/icons-material";

const ModalWindow = ({children}) => {
  const {isOpen, close} = useModal()
  if(!isOpen) return null

  const clickHandler = (e) => {
    if(e.target === e.currentTarget) close()
  }

  return (
    <div className={cn('modal-window')} onClick={clickHandler}>
      {children?.type === 'img' && <Close onClick={() => close()} style={{position: "absolute", top: '2%', right: '2%', color: '#fff', cursor: 'pointer', zIndex: 1000}}/>}
      <div className='modal-content'>
        {children}
      </div>
    </div>
  )
}

export default ModalWindow