export const REQUEST_COUNT = 12
export const LIMIT_COUNT = 48
export const EMAIL="help@maktun.com"
export const EMAIL_LINK="mailto: help@maktun.com"
export const TELEGRAM_LINK="https://t.me/MaktunApp"
export const WHATSAPP_LINK="https://wa.me/+996552799099"
export const VIBER_LINK="https://msng.link/o/?996552799099=vi"
export const EMAIL_SUBS="support@maktun.com"
export const EMAIL_LINK_SUBS="mailto: support@maktun.com"
export const TELEGRAM_LINK_SUBS="https://t.me/MaktunAppSupp"
export const WHATSAPP_LINK_SUBS="https://wa.me/+996508453515"
export const VIBER_LINK_SUBS="https://msng.link/o/?996508453515=vi"
export const FACEBOOK_LINK="https://www.facebook.com/maktunbot/"
export const VK_LINK="https://vk.com/public176853686"
export const GOOGLE_PLAY_LINK='https://play.google.com/store/apps/details?id=ru.vlcoins.catalog'
export const APP_STORE_LINK='https://apps.apple.com/kg/app/maktun-coin-and-note-search/id6443630368'
export const HUAWEI_LINK='https://appgallery.huawei.com/app/C100890371'
//export const API_URL = 'http://localhost/api'
export const API_URL = 'https://web.maktun.com/api'
export const STATISTIC_URL="https://eu.maktun.com/statistic/allow/"
