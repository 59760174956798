import React from 'react'
import cn from 'classnames'
import './TouchableModal.scss'
import { useModal } from '../hooks/useModal';

const TouchableModal = ({children}) => {
  const {isTouchableOpen, closeTouchable} = useModal()

  if(!isTouchableOpen) return null

  // const clickHandler = (e) => {
  //   if(e.target === e.currentTarget) close()
  // }

  return (
    <div className={cn('touchable-modal-window')}>
      <div className='modal-content'>
        {children}
      </div>
    </div>
  )
}

export default TouchableModal